import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    opened: false,
    show: { type: String, default: "block"},
    hide: { type: String, default: "hidden"},
  }
  static targets = ["togglable"]

  connect() {
    this.openedValue ? this.show() : this.hide();
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.openedValue ? this.hide() : this.show();
  }

  show() {
    if(this.hasTogglableTarget){
      this.togglableTarget.classList.add(this.showValue);
      this.togglableTarget.classList.remove(this.hideValue);
      this.openedValue = true;
    }
  }

  hide() {
    if(this.hasTogglableTarget){
      this.togglableTarget.classList.add(this.hideValue);
      this.togglableTarget.classList.remove(this.showValue);
      this.openedValue = false;
    }
  }
}
