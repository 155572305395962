// app/javascript/controllers/tooltip_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.createTooltip();
  }

  disconnect() {
    this.destroyTooltip();
  }

  createTooltip() {
    this.element.addEventListener('mouseenter', this.showTooltip.bind(this));
    this.element.addEventListener('mouseleave', this.hideTooltip.bind(this));
  }

  showTooltip() {
    const tooltipText = this.element.getAttribute('title') || this.element.dataset.title;
    if (!tooltipText) return;

    const tooltipElement = document.createElement('div');
    tooltipElement.className = 'z-300 transition-opacity ease-in-out absolute bg-black text-white text-sm p-1 rounded bs-override';
    tooltipElement.textContent = tooltipText;
    document.body.appendChild(tooltipElement);

    const arrowElement = document.createElement('div');
    arrowElement.className = 'z-300 transition-opacity ease-in-out absolute bg-black w-2_5 h-2_5 rotate-45 bs-override';
    document.body.appendChild(arrowElement);

    const { top, left, width } = this.element.getBoundingClientRect();
    const tooltipHeight = tooltipElement.offsetHeight;
    const tooltipWidth = tooltipElement.offsetWidth;

    tooltipElement.style.position = 'absolute';
    tooltipElement.style.top = `${top - tooltipHeight - 12}px`; // 8px gap
    tooltipElement.style.left = `${left + width / 2 - tooltipWidth  / 2}px`;

    arrowElement.style.top = `${top - 18}px`; // Positioned below the tooltip
    arrowElement.style.left = `${left + width / 2 - 6}px`;

    this.tooltipElement = tooltipElement;
    this.arrowElement = arrowElement;
  }

  hideTooltip() {
    if (this.tooltipElement) {
      this.tooltipElement.remove();
      this.arrowElement.remove();
      this.tooltipElement = null;
      this.arrowElement = null;
    }
  }

  destroyTooltip() {
    this.hideTooltip();
    this.element.removeEventListener('mouseenter', this.showTooltip);
    this.element.removeEventListener('mouseleave', this.hideTooltip);
  }
}
