// app/javascript/controllers/file_upload_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileInput", "dropZone", "loadArea", "fileInfo", "fileName", "progressBarContainer", "submitButton"]

  connect() {
    this.dropZoneTarget.addEventListener('dragenter', this.handleDragEnter.bind(this));
    this.dropZoneTarget.addEventListener('dragleave', this.handleDragLeave.bind(this));
    this.dropZoneTarget.addEventListener('dragover', this.handleDragOver.bind(this));
    this.dropZoneTarget.addEventListener('drop', this.handleDrop.bind(this));
  }

  handleDragEnter(event) {
    event.preventDefault();
    this.dropZoneTarget.classList.add('drag-over');
  }

  handleDragLeave(event) {
    event.preventDefault();
    this.dropZoneTarget.classList.remove('drag-over');
  }

  handleDragOver(event) {
    event.preventDefault();
  }

  handleDrop(event) {
    event.preventDefault();
    this.dropZoneTarget.classList.remove('drag-over');

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileInputTarget.files = files;
      this.simulateUpload();
    }
  }

  startLoad() {
    this.loadAreaTarget.style.display = "none";
    this.progressBarContainerTarget.style.display = "flex";
  }

  selectFile() {
    this.fileInputTarget.click();
  }

  simulateUpload() {
    this.startLoad();
    let loadCount = 0;

    const progressInterval = setInterval(() => {
      if (loadCount >= 5) {
        clearInterval(progressInterval);
        this.stopLoad();
        this.toggleSubmitButton(true);
      } else {
        loadCount += 1;
      }
    }, 200);
  }

  stopLoad() {
    this.progressBarContainerTarget.style.display = 'none';
    this.fileInfoTarget.style.display = 'flex';
    this.fileNameTarget.textContent = this.fileInputTarget.files[0].name
  }

  removeFile() {
    this.fileInputTarget.value = '';
    this.fileInfoTarget.style.display = 'none';
    this.progressBarContainerTarget.style.display = 'none';
    this.loadAreaTarget.style.display = 'flex';
    this.toggleSubmitButton(false);
  }

  toggleSubmitButton(isEnabled) {
    if (isEnabled) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', true);
    }
  }
}
